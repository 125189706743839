<template>
  <CRow>
    <CCol col="12">
      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ $t('Edit gmail config') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event && $event.target ? $event.target.name : 'test')">
            <ACard>
              <AInput :horizontal="{ label: 'col-sm-3', input: 'col-sm-6' }" name="inbox_email" label="Email" readonly v-model="form.inbox_email" :isValid="isValid('inbox_email')" :errors="getErrors('inbox_email')" />
              <AInput :horizontal="{ label: 'col-sm-3', input: 'col-sm-6' }" name="accepted_email" label="Forward email" v-model="form.accepted_email" :isValid="isValid('accepted_email')" :errors="getErrors('accepted_email')" />
              <AInput :horizontal="{ label: 'col-sm-3', input: 'col-sm-6' }" name="accepted_label_id" label="Forwarded" v-model="form.accepted_label_id" />
              <AInput :horizontal="{ label: 'col-sm-3', input: 'col-sm-6' }" name="rejected_label_id" label="Rejected" v-model="form.rejected_label_id" />
            </ACard>
          </CForm>
          <CCardFooter>
            <CButton class="mr-2" color="info" adisabled="!isFormValid" @click="submit">{{ $t('Save') }}</CButton>
            <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import formMixin from '@/app/views/_mixins/form-mixin'

import { DEBUG } from '@/config/config'

export default {
  name: 'GMailConfigForm',
  mixins: [formMixin],

  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper

      // DATA
      form: {},
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/gmail/config')
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },

    // Parse Extra and Related data
    parseData(data) {
      this.form = data.config ? data.config : { id: '' }
      this.form.inbox_email = this.form.id

      this.parseView()

      this.validators = {
        main: data._validation || {}
        //extra: data._extra_validation || {}
      }

      if (_.isEmpty(this.$v.$form)) this.parseValidator(data._validation, data._messages, true)

      //if (this.form.id) this.forcedSteps()
    },

    // Parsing

    parseView() {
      const options = [
        { click: this.submit, class: 'mr-2 btn-info', content: this.$t('Save') }, //disabled: this.isFormValid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { ['EditGmailConfig']: options })
    },

    // SUBMIT

    submit() {
      //console.log(this.form)

      this.$v.form.$touch()
      if (this.$v.form.$error) return

      const self = this
      self.$http['put']('admin/gmail/config', self.form) // TODO: on service ?
        .then(response => {
          self.showAlert('Gmail config saved')
          self.parseData(response.data)
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            console.log('parseInputErrors')
            self.setErrors(error.response.data.errors || {})
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert('There was an error saving the config')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>
